import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Guiding principles`}</h2>
    <h3>{`Maintain context for users`}</h3>
    <p>{`When designing interfaces that incorporate progressive disclosure, refrain from creating interactions that drastically disorient the user’s initial point of focus.`}</p>
    <h3>{`Progressive disclosure vs navigation`}</h3>
    <p>{`It's important to understand the difference between progressive disclosure and navigation patterns. Because the two share common elements, it can be confusing when deciding which type to implement.`}</p>
    <p>{`Please refer to these guidelines as they detail in-use solutions, and outline best practices for implementation. If you need more help, refer to the `}<a parentName="p" {...{
        "href": "#support"
      }}>{`support`}</a>{` section of this article for how to get in touch.`}</p>
    <h3>{`Pair icons with text when possible`}</h3>
    <p>{`Pair progressive disclosure icons with descriptive text to provide context.`}</p>
    <h3>{`Implement only as necessary`}</h3>
    <p>{`Progressive disclosure should be used sparingly, when it’s necessary to truncate information for the general layout/design.`}</p>
    <h2>{`Progressive disclosure UI patterns`}</h2>
    <p>{`The following table outlines common progressive disclosure solutions in use at GitHub.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#chevron-icon"
            }}>{`Chevron icon`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/24916540/52089727-1ae9d480-2564-11e9-8291-1ee9d3225427.png",
              "alt": "chevron"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When elements of content are collapsed and can be toggled open`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Do not use this icon to trigger dropdown menus or navigation; the caret icon is the more suitable for this.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#foldunfold"
            }}>{`Fold/Unfold icon`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/24916540/52089724-1a513e00-2564-11e9-84f8-eba077f6abfc.png",
              "alt": "expand"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Signify that there is content (typically text) to be revealed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Should generally stand alone, rather than being paired with text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#ellipsis-icon"
            }}>{`Ellipsis icon`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/24916540/52089725-1ae9d480-2564-11e9-8d29-1dc28740924a.png",
              "alt": "ellipsis"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For toggling truncated inline text content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Do not use this icon to trigger dropdown menus or navigation; the Kebab icon is the more suitable for this.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text-only toggles`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Usage of this solution is discouraged, as generally icons or icon+text pairings provide better accessibility and more information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Kebab`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/24916540/52089726-1ae9d480-2564-11e9-984b-2d304cc0d46e.png",
              "alt": "kebab"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For toggling inline dropdowns and menus`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Caret`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/24916540/52089723-1a513e00-2564-11e9-8179-420bba7922e7.png",
              "alt": "caret"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Refrain from using this icon as a progressive disclosure solution`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Chevron icon`}</h3>
    <p>{`The chevron icon is used when elements of content are collapsed and can be toggled open. Typically this icon is positioned vertically, and alternates between the “up” state when expanded and the “down” state when collapsed. This icon is quite flexible, and can stand alone, or be paired with text.`}</p>
    <Box display="flex" alignItems="center" flexDirection="column" my={5} mdxType="Box">
  <Box borderWidth="1px" borderStyle="solid" borderColor="border.default" borderRadius={2} width={9 / 12} mb={2} overflow="hidden" mdxType="Box">
    <img src="https://user-images.githubusercontent.com/24916540/52079947-8a9f9580-254b-11e9-9e64-72a2a38f5ebd.png" />
  </Box>
  <p>Chevron icon used to display more branches in mobile view</p>
    </Box>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/24916540/52094309-7b344280-2573-11e9-8f1a-d28cf78a0351.png" />
    <Caption mdxType="Caption">Utilize appropriate text in scenarios to provide clarity</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/24916540/52088378-892c9800-2560-11e9-9e36-0e72599da28a.png" />
    <Caption mdxType="Caption">Don't use the chevron to trigger dropdowns</Caption>
  </Dont>
    </DoDontContainer>
    <p><strong parentName="p">{`Caution:`}</strong></p>
    <ul>
      <li parentName="ul">{`The chevron should not be used as a call-to-action for dropdown menus`}</li>
      <li parentName="ul">{`It should not be used for pagination, or indicate directional actions`}</li>
    </ul>
    <h3>{`Fold/Unfold`}</h3>
    <p>{`This icon is used to signify that there is content that can be toggled open and closed. It is typically surrounded by text content above and below it. There are currently versions of this icon that expand the context in a singular direction, but are only used in GitHub’s code review editor.`}</p>
    <Box display="flex" alignItems="center" flexDirection="column" my={5} mdxType="Box">
  <Box borderWidth="1px" borderStyle="solid" borderColor="border.default" borderRadius={2} width={9 / 12} mb={2} overflow="hidden" mdxType="Box">
    <img src="https://user-images.githubusercontent.com/24916540/52096167-c43bc500-257a-11e9-9e20-a0371eb83720.png" />
  </Box>
  <p>Fold/Unfold Icon used in Profiles context</p>
    </Box>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/24916540/52096601-47114f80-257c-11e9-92fc-d5a12f980372.png" />
    <Caption mdxType="Caption">Use this icon to show content expansion in the code editor</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/24916540/52090486-5b4a5200-2566-11e9-8f29-3c26f3787409.png" />
    <Caption mdxType="Caption">Don't pair this icon with lengthy text</Caption>
  </Dont>
    </DoDontContainer>
    <p><strong parentName="p">{`Caution:`}</strong></p>
    <ul>
      <li parentName="ul">{`This icon should generally be used to signify the expansion of text content`}</li>
      <li parentName="ul">{`Generally this icon is not displayed with accompanying text`}</li>
    </ul>
    <h3>{`Ellipsis icon`}</h3>
    <p>{`The ellipsis icon is used for toggling truncated inline text content. Its primary purpose is to serve as a method that designers to use to curtail bodies of texts, but sometimes other types of information.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/24916540/52093713-14ae2500-2571-11e9-9893-819b563639b1.png" />
    <Caption mdxType="Caption">Use this icon to give users the option to toggle the amount of text being displayed</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/24916540/52093714-1546bb80-2571-11e9-8799-9260792e40f5.png" />
    <Caption mdxType="Caption">Don't substitute a Kebab icon in the place of an ellipsis</Caption>
  </Dont>
    </DoDontContainer>
    <p><strong parentName="p">{`Caution:`}</strong></p>
    <ul>
      <li parentName="ul">{`While visually similar, the ellipsis icon is different from the kebab icon, which is used for dropdown menus or general call-to-actions. Be careful not to confuse them for one another`}</li>
      <li parentName="ul">{`This icon generally stands alone within inline bodies of text`}</li>
    </ul>
    <h2>{`Support`}</h2>
    <h3>{`Iterate and componentize`}</h3>
    <p>{`If or when certain progressive disclosure patterns emerge in your design, or you believe a certain solution should be made into a component, reach out to the `}<a parentName="p" {...{
        "href": "https://github.slack.com/archives/CSGAVNZ19"
      }}>{`#primer`}</a>{` channel on Slack to discuss how you can submit your code or idea. You may also reach out by opening an issue to the Design Systems team directly on GitHub.`}</p>
    <h3>{`Requesting feedback`}</h3>
    <p>{`Progressive disclosure can be tricky to implement, and certain designs and scenarios may test the bounds of our design guidelines. If you have further questions or need clarity on implementation, please get in touch via the `}<a parentName="p" {...{
        "href": "https://github.slack.com/archives/CSGAVNZ19"
      }}>{`#primer`}</a>{` channel on Slack.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      